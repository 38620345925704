/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
@mixin Smallmobile {
  @media (max-width: 360px) {
    @content;
  }
}
@mixin Minmobile {
  @media (max-width: 500px) {
    @content;
  }
}
:root {
  --homepage-jeans-background: #f8f8f8;
  --content-wrapper-width: 1200px;
}
//variables

$LayerColor1: #f5f2ed;
$LayerColor2: #90cc8d;
$LayerColor3: white;

.homage-jeans-preview {
  padding: 2.4rem 4.8rem;
  background: var(--homepage-jeans-background);
  margin: 2.4rem 0 1.2rem;

  @include mobile {
    margin: 2.8rem 0 1.4rem;
    padding: 2.8rem 1.4rem;
  }

  h2 {
    margin: 0;
  }
}

// PCR block
.PCR {
  background-color: #ffff;
  a {
    .Image-Image {
      @include mobile {
        height: auto;
      }
    }
    .Image_ratio_square {
      padding-bottom: 31%;
      @include mobile {
        padding-bottom: 68%;
      }
    }
  }
}
.PCR-BackgroundImage-mobile {
  @include desktop {
    display: none;
  }
}
.PCR-BackgroundImage {
  @include mobile {
    display: none;
  }
}

// 1st static block
.CardBackground {
  padding-top: 10rem;
  padding-bottom: 100px;
  width: 100%;
  height: 100%;
  background-color: $LayerColor3;
  @include mobile {
    background-color: $LayerColor1;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @include tablet {
    background-color: $LayerColor1;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.CardMain {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  width: var(--content-wrapper-width);

  @include medium-desktop {
    width: 100%;
  }
  margin: auto;
  overflow: hidden;

  @include mobile {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "CommonSymtoms UploadPrescription"
      "HomeHealthServices CorpratePartnership";
    margin: auto;
    grid-gap: 10px;
    margin: {
      left: auto;
      right: auto;
    }
    padding-bottom: 50px;
  }
  @include Minmobile {
    grid-gap: unset;
  }

  @include tablet {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "CommonSymtoms UploadPrescription"
      "HomeHealthServices CorpratePartnership";

    grid-gap: 10px;
    margin: {
      left: auto;
      right: auto;
    }
    padding-bottom: 50px;
  }
  &-Card {
    margin-top: 60px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    a {
      text-decoration: unset !important;
    }

    &-Top {
      cursor: pointer;
      background-color: $LayerColor1;
      height: 110px;
      width: 250px;
      border-radius: 15px 15px 0px 0px;

      @include mobile {
        background-color: $LayerColor3;
        width: 200px;
        height: 70px;
      }
      @include Minmobile {
        width: 170px;
      }
      @include Smallmobile {
        width: 120px;
        height: 50px;
      }
      @include tablet {
        background-color: $LayerColor3;
        width: 250px;
        height: 80px;
      }
      &-ImageLayer1 {
        position: absolute;
        bottom: 22px;
        left: 51px;
        background-color: $LayerColor3;
        padding: 8px;
        border-radius: 100%;
        @include mobile {
          padding: 10px;
          bottom: 10px;
          left: 53px;
        }
        @include Minmobile {
          left: 40px;
        }
        @include Smallmobile {
          left: 26px;
          top: -30px;
          padding: 7px;
        }
        @include tablet {
          padding: 13px;
          top: -40px;
          left: 6.7rem;
        }
        &-ImageLayer2 {
          padding: 26px;
          border: 5px solid $LayerColor1;
          border-radius: 100%;
          background-color: $LayerColor3;
          @include mobile {
            padding: 5px;
            border: 5px solid $LayerColor1;
            border-radius: 100%;
            background-color: $LayerColor1;
          }

          @include tablet {
            padding: 5px;
            border: 5px solid $LayerColor1;
            border-radius: 100%;
            background-color: $LayerColor1;
          }
          div {
            width: 70px;
            height: 70px;

            @include mobile {
              width: 50px;
              height: 50px;
            }
            @include Smallmobile {
              width: 35px;
              height: 35px;
            }
            @include tablet {
              width: 60px;
              height: 60px;
            }
            img {
              width: 70px;
              height: 70px;
              padding: 5px;
              @include mobile {
                width: 10px;
                height: 10px;
              }
              @include tablet {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
    }
    &-Title {
      width: 250px;
      background-color: $LayerColor2;
      color: $LayerColor3;
      padding: 15px 0;
      font-size: 20px;
      font-weight: bold;
      border-radius: 0px 0px 15px 15px;
      text-align: center;
      @include mobile {
        font-size: 13px;
        padding: 10px 0;
        width: 200px;
      }
      @include Minmobile {
        width: 170px;
      }
      @include Smallmobile {
        width: 120px;
        font-size: 8px;
      }
      @include tablet {
        font-size: 15px;
        padding: 10px 0;
        width: 250px;
      }
    }
  }
}
