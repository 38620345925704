/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --overlay-background: var(--secondary-base-color);
  --overlay-content-background: #fff;
  --overlay-desktop-border-color: var(--primary-light-color);

  @include desktop {
    --overlay-background: #fff;
  }
}

.Overlay {
  @include mobile {
    margin-top: 100px;
  }
  pointer-events: none;
  will-change: opacity;
  z-index: 10;
  opacity: 0;

  background-color: var(--overlay-background);

  &:not(.Overlay_isStatic) {
    @include before-desktop {
      -webkit-overflow-scrolling: touch;

      position: fixed;
      top: var(--header-total-height);
      left: 0;
      width: 100%;
      height: calc(100% - var(--header-total-height));
      padding-bottom: var(--navigation-tabs-height);
      overflow: scroll;
      overflow-x: hidden;
      max-width: 100%;
    }
  }

  @include desktop {
    position: absolute;
    transition: opacity 200ms;
    height: 0;
    overflow: hidden;
  }

  &_isVisible {
    pointer-events: all;
    touch-action: pan-y;
    opacity: 1;
    z-index: 99;

    @include desktop {
      height: auto;
      overflow: visible;
    }
  }

  // > * {
    // background-color: var(--overlay-content-background);
  // }
}
