/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --image-default-background: var(--secondary-base-color);
  --image-content-color: #{$black};
}

.Image {
  display: inline-block;
  // overflow: hidden;
  // height: 0;
  width: 100%;
  background: var(--image-default-background);
  // background-color: lightgray;

  &_ratio {
    &_16x9 {
      padding-bottom: 56.25%;
    }

    &_4x3 {
      padding-bottom: 75%;
    }

    &_square {
      padding-bottom: 100%;
    }
  }

  &_imageStatus_0,
  &_isPlaceholder {
    background-image: var(--placeholder-image);
    background-size: var(--placeholder-size);
    animation: var(--placeholder-animation);
  }

  &_hasSrc,
  &_imageStatus_1 {
    background: none;
  }

  &-Content {
    position: absolute;
    height: max-content;
    width: 100%;
    text-align: center;
    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;

    color: var(--image-content-color);
    font-size: 1.4rem;

    &_isOffline {
      width: 100%;
      height: 100%;
      background: var(--image-default-background);
    }
  }

  &-Image {
    position: absolute;
    top: 0;
    // margin-top: 4rem;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    object-fit: contain;

    @include mobile {
      margin-top: 0;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--image-default-background);
    }
  }
}
