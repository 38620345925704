/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    list-style-type: none;
    text-decoration: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

li::before,
ul > li::before {
    content: unset !important;
}
li {
    padding: unset;
}
a:hover,
a:focus {
    text-decoration: none !important;
}

.mobile-menu-container {
    max-width: 80rem;
    width: 100%;
    height: auto;
    padding: 0 2rem;
    margin: 0 auto;
}

.open-menu,
.close-menu {
    cursor: pointer;
    border: none;
    outline: none;
    color: #fff;
    background: none;
}

.close-menu {
    position: absolute;
    top: 0;
    left: 1rem;
    border: none;
    outline: none;
    color: #fff;
    background: none;
}
.close-menu-button {
    width: 36px;
    margin-top: 5px;
}

.brand {
    font-family: inherit;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.5;
    color: #d32f2f;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
}

.title-large {
    font-family: inherit;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: inherit;
    color: #252a32;
    text-align: center;
    text-transform: capitalize;
}

.header {
    position: relative;
    width: 100%;
    height: auto;
    // padding: 0.75rem 1.5rem;
    color: #252a32;
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
}
.header .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
}
.header .navbar .menu-wrapper::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: background 0.5s;
}
.header .navbar .menu-wrapper.offcanvas .menu {
    transform: translate3d(0, 0, 0);
    transition-duration: 0.7s;
    transition-delay: 0.2s;
}
.header .navbar .menu-wrapper.offcanvas::before {
    background: rgba(37, 42, 50, 0.6);
    z-index: 1;
}
.header .navbar .menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1.5rem 1rem;
    z-index: 2;
    overflow-y: auto;
    color: #252a32;
    background: var(--brand-blue-color);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.header .navbar .menu-block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
}
.header .navbar .menu-link {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: inherit;
    text-transform: uppercase;
    transition: color 0.35s ease-out;
}
.header .navbar .menu-link:hover {
    color: #d32f2f;
}
.login-link {
    float: right;
    position: absolute;
    // left: 180px;
    right: 0;
    margin-right: 20px;
    z-index: 9;
    top: 25px;
    color: #90cc8d;
    font-size: 17px;
}

.login-link a {
    color: #90cc8d;
}
.level-menu-wrapper {
    margin-top: 2rem;
}
.menu-item-first-level {
    margin-top: 2rem;
    padding-left: 10px;
}
.menu-item-first-level-link {
    color: white;
    font-size: 25px;
}
.menu-item-second-level {
    li {
        padding-left: unset;
    }
    margin-top: 10px;
    padding-left: 20px;
}
.menu-item-second-level-link {
    color: rgb(228, 228, 228);
    font-size: 20px;
}
.menu-item-third-level {
    li {
        padding-left: unset;
    }
    margin-top: 10px;
    padding-left: 23px;
}
.menu-item-third-level-link {
    color: rgb(211, 211, 211);
    font-size: 18px;
}
.all-level-link-arrow-up {
    width: 15px;
    height: 15px;
    margin-left: 5px;
}
