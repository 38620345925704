/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';

.CategoryRight {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 2rem;
    left: 35rem;
    right: 0;
    transition: opacity 200ms ease-in;
    will-change: transition;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-height: 45rem;
    overflow: scroll;
    margin: auto;
    background-color: #ffff;
    padding: 2rem 4rem;
    border-radius: 4px;

    @include mobile {
        background-color: var(--brand-blue-color);
        border: 1px solid white;
        left: 40rem;
    }

    &-Titles {
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 15px;

        &-Name {
            color: #1e295b;
            font-size: 15px;
            cursor: pointer;
            @include mobile {
                color: white;
            }
        }
        &-Description {
            &-Link {
                color: #6a6a6a;
            }
            color: #6a6a6a;
            cursor: pointer;
            margin-top: 5px;
            display: flex;
            flex-direction: column;

            @include mobile {
                color: white;
            }
            &:hover {
                // @keyframes mymove {
                //     from {
                //         left: 0px;
                //     }
                //     to {
                //         left: 5px;
                //     }
                // }
            }
        }
    }
}
