/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --menu-background-color: #fff;
  --menu-desktop-background-color: rgba(122, 122, 122, 0.44);
  --menu-desktop-height: 60px;
  --menu-item-figure-background: var(--secondary-base-color);
  --menu-item-hover-color: var(--primary-base-color);
  --menu-second-level-color: #adadad;
}

@mixin subcategory-visible {
  pointer-events: all;
  z-index: 10;
  opacity: 1;
  transform: translateX(0);
}

.Menu {
  &-MenuWrapper {
    padding: 1.2rem;
    padding-bottom: 3.5rem;

    @include mobile {
      // padding: 3rem 1.4rem 1.4rem 1.4rem;
      background-color: var(--brand-blue-color);
    }

    @include desktop {
      min-height: var(--menu-desktop-height);
      // margin: calc(var(--header-nav-height) * -1) auto auto;
      padding: 0;
    }

    li {
      @include mobile {
        padding-left: 0;
      }

      &::before {
        content: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-Overlay {
    background-color: var(--menu-desktop-background-color);
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: -3px;
    left: 0;
    opacity: 0;
    transition: opacity 200ms ease-in;
    will-change: transition;

    &_isVisible {
      opacity: 1;
    }
  }

  &-MainCategories {
    @include before-desktop {
      display: flex;
      // flex-direction: column;
    }

    @include desktop {
      display: block;
      padding-left: 1.2rem;
      padding-right: 40px;
      // z-index: 100;
      // max-width: var(--content-wrapper-width);
      width: 100%;
      margin: auto;
      pointer-events: none;
    }
  }

  &-ItemList {
    display: grid;

    @include desktop {
      display: flex;
    }

    &_type {
      &_main {
        @include desktop {
          pointer-events: auto;
          width: min-content;
        }

        @include before-desktop {
          order: 2;
        }
      }

      &_subcategory {
        background: var(--menu-background-color);
        grid-auto-rows: max-content;
        padding: 0 1.2rem;

        @include mobile {
          padding: 0 3.5rem;
          background-color: var(--brand-blue-color);
          color: var(--brand-white-color);
        }

        @include desktop {
          padding: 0;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }

  &-ItemCaption {
    margin-top: 23px;
    @include mobile {
      font-size: 1.2rem;
    }
    &_type {
      &_main {
        font-size: 1.8rem;
        // font-weight: bold;
        line-height: 1.2;
        text-align: left;

        @include mobile {
          font-size: 1.2rem;
          color: var(--brand-white-color);
          width: 200px;
        }

        @include desktop {
          font-size: 1.4rem;
          font-weight: normal;
          text-transform: none;
        }
      }

      &_subcategory {
        font-size: 1.2rem;
        font-weight: normal;
        padding: 1.2rem 0;

        @include mobile {
          font-size: 1.2rem;

          padding: 1.4rem 0;
        }

        @include desktop {
          padding-left: 0;
        }
      }
    }

    &_isLogo {
      font-size: 1.1rem;
    }

    &_isBanner {
      --button-background: var(--homepage-button-background);
      --button-border: transparent;
      --button-color: var(--homepage-button-color);
      --button-hover-background: var(--homepage-button-color);
      --button-hover-border: transparent;
      --button-hover-color: var(--homepage-button-background);
      --button-padding: 9px 49px;

      bottom: 20px;
      left: 50%;
      position: absolute;
      width: 100%;
      transform: translateX(-50%);
    }
  }

  &-ItemFigure {
    align-items: center;
    display: grid;

    &_type {
      &_main {
        padding-left: 30px;
        // text-transform: uppercase;
        font-weight: unset !important;

        @include before-desktop {
          //  background-color: var(--menu-item-figure-background);
          grid-auto-flow: column;
          grid-template-columns: 50% 50%;
          //  height: 100px;
        }

        @include desktop {
          // height: var(--menu-desktop-height);
          padding-left: 0;
          z-index: 20;
        }
      }
    }

    &_isBanner {
      @include desktop {
        overflow: hidden;
        width: 200px;
      }
    }

    &_isLogo {
      display: flex;

      .Image-Image {
        object-fit: cover;
      }
    }

    &_isHideOnDesktop {
      @include desktop {
        display: none;
      }
    }
  }

  &-Item {
    @include before-desktop {
      margin-top: 0.6rem;
      padding-left: 0;
    }

    @include mobile {
      margin-top: 0.7rem;
      padding-left: 0;
    }

    @include desktop {
      margin-bottom: 0;
      padding: 0 1.7rem;
    }

    @include tablet-portrait {
      padding: 0 0.5rem;
    }

    &:first-child {
      margin-top: 0;

      @include desktop {
        padding-left: 0;
      }
    }

    button {
      width: 100%;
    }

    &_isHideOnDesktop {
      @include desktop {
        display: none;
      }
    }
  }

  &-SubCategoriesWrapper {
    position: absolute;
    top: calc(var(--header-nav-height) + 3px);
    pointer-events: none;
    width: 100%;

    @include tablet {
      top: var(--header-nav-height);
      left: 0;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      top: calc(var(--header-nav-height) * -1);
    }

    &_isVisible {
      pointer-events: all;
    }
  }

  &-SubCategoriesWrapperInner {
    background-color: var(--overlay-background);
    transition: clip-path 200ms ease-in;
    will-change: clip-path;
    clip-path: inset(0 0 100% 0);
    margin: auto;
    overflow-y: auto;
    max-height: calc(100vh - var(--header-nav-height));
    @include desktop {
      position: fixed;
    }
    &_isVisible {
      clip-path: inset(0);
    }
  }

  &-SubCategories,
  &-Promotion {
    max-width: var(--content-wrapper-width);
    margin: auto;
    display: none !important;
  }

  &-Promotion {
    order: 3;
  }

  &-SubCategories {
    padding: 40px 55px 60px 1.2rem;

    @include mobile {
      padding: 40px 55px 60px 1.4rem;
    }
  }

  &-SubMenu {
    background-color: var(--overlay-background);
    opacity: 0;
    pointer-events: none;
    touch-action: pan-y;
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;

    @include before-desktop {
      height: 100%;
      left: 0;
      //  padding-top: var(--header-total-height);
      position: fixed;
      top: 0;
      width: 100%;
    }

    @include desktop {
      background: transparent;
      transform: translateX(0);
      transition-duration: 0ms;
      width: 100%;
    }

    &_isVisible {
      @include subcategory-visible;
      @include mobile {
        //overflow-y: scroll;
        background-color: var(--brand-blue-color);
      }

      .Menu-Link {
        @include desktop {
          padding: 0;
        }
      }
    }

    .Menu-Link {
      @include desktop {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &-SubItemWrapper {
    .Image-Content {
      @include mobile {
        display: none;
      }
    }
    flex-basis: 200px;

    @include desktop {
      margin-right: 4.8rem;
      margin-bottom: 2.4rem;
    }

    &_isBanner {
      flex-grow: 1;
      flex-basis: 400px;
    }

    &_isHideOnDesktop {
      @include desktop {
        display: none;
      }
    }

    > .Menu-Link {
      display: block;

      @include desktop {
        color: var(--menu-second-level-color);
        font-size: 1.4rem;
        text-transform: uppercase;
      }
    }
  }

  &-SubLevelDesktop {
    .Menu {
      &-ItemList {
        display: block;
        padding: 0;

        &_isBanner {
          display: grid;
          grid-column-gap: 10px;
          grid-template-columns: repeat(auto-fit, 200px);
        }
      }

      &-Link {
        margin-top: 20px;
      }
    }
  }

  &-Image {
    &_isBanner {
      @include desktop {
        padding-bottom: 100%;
      }

      img {
        object-fit: cover;
      }
    }

    &_isLogo {
      height: 44px;
      margin-right: 20px;
      width: 44px;
    }

    &_type {
      &_main {
        grid-column: 2;
        height: 100%;
        justify-self: end;
        padding-bottom: 0;
      }

      &_subcategory {
        border-radius: 50%;
        height: 3.84rem;
        padding-bottom: 0;
        width: 3.84rem;

        @include mobile {
          height: 4.48rem;
          width: 4.48rem;
        }
      }
    }
  }

  &-Link {
    color: inherit;
    white-space: nowrap;
    padding-left: 0;

    @include before-desktop {
      padding: 1.2rem 0;
    }

    @include mobile {
      padding: 1.4rem 0;
      color: var(--brand-white-color);
      font-size: 1.7rem;
    }

    @include desktop {
      display: block;
      margin-right: 0;
    }

    &:hover,
    &_isHovered,
    &:focus {
      color: var(--menu-item-hover-color);
      text-decoration: none;
    }
  }

  &-Link + &-Link {
    margin-left: 0;
  }

  .Image {
    background: none;
  }

  &-CompareLinkWrapper {
    text-transform: uppercase;
    order: 1;
  }

  &-CompareLink {
    display: none !important;
    color: var(--brand-white-color);

    &:hover {
      text-decoration: none;
    }
  }
}
