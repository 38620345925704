/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --footer-height: calc(
    var(--footer-content-height) + var(--footer-copyright-height)
  );
  --footer-content-height: 320px;
  --footer-copyright-height: 50px;
  --content-wrapper-width: 1200px;
}

.Footer {
  background-color: #3a3a3a;
  .FooterDown {
    // > *:not(:last-child) {
    > * {
      @include mobile {
        margin: 5px 0;
      }
    }
    padding: {
      top: 10px;
      bottom: 5px;
    }
    @include mobile {
      padding: 10px 20px 5px 20px;
    }
    display: flex;
    @include mobile {
      flex-wrap: wrap;
    }
    max-width: var(--content-wrapper-width);
    margin: auto;
    justify-content: space-between;
    // padding: 20px;
    &-FooterLeft {
      @include desktop {
        width: 25%;
      }
      display: flex;
      align-items: center;
      justify-content: left;
      color: white;
      font-weight: bold;
      font-size: 13px;
      // padding-top: 14px;
      @include mobile {
        width: 100%;
        justify-content: center;
      }
    }
    &-TechProvided {
      @include desktop {
        width: 25%;
      }
      @include mobile {
        width: 100%;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: bold;
      font-size: 13px;
    }
    &-HodLink {
      margin-left: 4px;
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
    &-FooterMiddle {
      @include desktop {
        width: 25%;
      }
      @include mobile {
        width: 100%;
      }
      display: flex;
      align-items: center;
      justify-content: center;

      color: white;
      font-weight: bold;
      font-size: 13px;
      // padding-top: 14px;
    }
    &-FooterRight {
    //   @include desktop {
    //     width: 25%;
    //   }
      display: flex;
      align-items: center;
      justify-content: right;

      @include mobile {
        width: 100%;
        justify-content: center;
      }
      &-Img {
        margin-left: 10px;
        height: auto;
        width: 50px;
        @include mobile {
          width: 35px;
        }
      }
    }
  }
  $column-count: 4;

  @include mobile {
    margin-bottom: var(--navigation-tabs-height);
  }

  &-CopyrightContentWrapper {
    background-color: var(--secondary-dark-color);
  }

  &-CopyrightContent {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: var(--footer-copyright-height);

    @include after-mobile {
      padding: 15px 0;
    }
  }

  &-Copyright {
    font-size: 1.5rem;
    // text-align: center;

    a {
      color: inherit;
    }
  }

  &-Content {
    background-color: white !important;
    .DownContent {
      margin: {
        top: 20px;
        bottom: 20px;
        left: auto;
        right: auto;
      }
      text-align: center;
      font-size: 12px;
      width: 78%;
      @include mobile {
        width: 90%;
      }
    }
    // min-height: var(--footer-content-height);
    padding: {
      top: 20px;
      bottom: 20px;
    }
    background-color: var(--secondary-base-color);
  }

  &-Columns {
    display: flex;
    height: 100%;
    padding: 25px;
    justify-content: space-between;

    @include mobile {
      display: block;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    @include after-mobile {
      //   padding: 60px 70px;
    }

    @include tablet {
      padding: {
        left: 30px;
        right: 30px;
      }
    }
  }

  &-Column {
    &:not(:last-of-type) {
      @include mobile {
        margin-bottom: 30px;
        width: 48%;
        display: inline-block;
        text-align: left;
      }

      @include after-mobile {
        // padding-right: 20px;
        // margin-right: 30px;
        // max-width: calc(100% / #{$column-count - 1});
      }
    }
  }

  &-ColumnTitle {
    @include mobile {
      text-align: left;
      font-size: 12px;
      letter-spacing: 1px;
    }
    // text-transform: uppercase;
    margin: 0 0 15px;
    // font-size: 2rem;

    @include after-mobile {
      margin: 0 0 20px;
    }
  }

  &-ColumnContent {
    display: flex;
    flex-direction: column;

    .Contacts {
      display: flex;
      @include mobile {
        text-align: left;
        // justify-content: center;
        justify-content: left;
      }
      font-size: 1.3rem;
      .LastColumnLabel {
        margin-right: 10px;
        margin-bottom: 12px;
      }
    }
    .SocialLinks {
      margin-top: 12px;
      @include mobile {
        // margin-left: auto;
        // margin-right: auto;
        margin-left: inherit;
        margin-right: inherit;
      }
      width: 100px;
      display: flex;
      justify-content: space-between;
      .SocialIcon {
        width: 20px;
      }
    }
    .ColContent {
      font-size: 1.3rem;
      width: 300px;
      @include mobile {
        text-align: left;
      }
    }

    &_direction_horizontal {
      flex-direction: row;

      @include mobile {
        justify-content: center;
      }
    }
  }

  &-ColumnItem {
    color: inherit;
    margin-bottom: 12px;
    font-size: 1.3rem;
    @include mobile {
      font-size: 1.1rem;
    }

    &:last-of-type {
      margin: 0;
    }

    &_type_image {
      width: 25px;

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }

  &-CmsBlockWrapper {
    .CmsBlock-Wrapper {
      flex: 1;
    }

    .ContentWrapper .Footer-Columns {
      padding: 0;
    }

    .Footer-Column_isNewsletter {
      @include desktop {
        padding-left: 100px;
      }
    }
  }
  .TechProwered {
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 13px;
    padding-bottom: 20px;
  }
}
