/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Field {
  margin-top: 1.2rem;

  @include mobile {
    margin-top: 1.4rem;
  }

  &-Label[for='guest_email'] ~ p {
    margin-left: 9px !important;
  }
  &-Label[for='guest_email'] {
    margin-left: 9px;
  }
  &-Label {
    &_isRequired:after {
      content: ' *';
      // color: rgb(36, 36, 36);
      color: var(--brand-blue-color);
    }
    font-weight: 700;
    padding-bottom: 0.36rem;
    @include desktop {
      // margin-left: 10px;
    }

    @include mobile {
      padding-bottom: 0.42rem;
    }
  }

  &-Message {
    margin-bottom: -0.1em;
    font-size: 1.26rem;
    font-weight: 600;
    color: var(--primary-error-color);

    @include mobile {
      font-size: 1.47rem;
    }
  }

  &-Note {
    margin-bottom: 0.24rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--field-note);
    opacity: 0;
    height: 0;
    transition: height 0.1s ease-in, opacity 0.1s linear;
    will-change: height, opacity;

    @include mobile {
      margin-bottom: 0.28rem;
      font-size: 1.4rem;
    }

    &::before {
      content: '* ';
    }
  }

  //   &_isValid {
  //     input,
  //     textarea {
  //       // Change here
  //       border: 1px solid var(--primary-success-color);
  //     }
  //   }

  &_hasError {
    input,
    textarea {
      border: 1px solid var(--primary-error-color);
    }
  }

  &::placeholder {
    color: var(--field-note);
    font-size: 1.08rem;

    @include mobile {
      font-size: 1.26rem;
    }
  }

  input:focus + .Field-Note {
    opacity: 1;
    height: 100%;
  }

  &_style {
    &_inline {
      $size-mobile: 20px;
      $size: 28px;
      $border-color: #000;
      $input-width-mobile: 35px;
      $input-width: 35px;

      &.Field {
        display: flex;
        margin: 0;

        button {
          position: relative;
          width: $size-mobile;
          height: $size-mobile;
          border: solid 1px $border-color;

          @include after-mobile {
            width: $size;
            height: $size;
          }

          &:last-of-type {
            order: -1;
          }
        }
      }

      input {
        height: $size-mobile;
        min-height: $size-mobile;
        width: $input-width-mobile;
        min-width: $input-width-mobile;

        border: none;
        padding: 0;
        text-align: center;

        @include mobile {
          font-size: 1.4rem;
        }

        @include after-mobile {
          height: $size;
          min-height: $size;
          width: $input-width;
          min-width: $input-width;
        }

        &:focus {
          border: none;
        }
      }
    }
  }

  &_type {
    &_checkbox,
    &_radio {
      label {
        font-size: 13px;
        &:first-of-type {
          display: inline-block;
          max-width: calc(100vw - 2.4rem - 45px);
          vertical-align: middle;

          @include mobile {
            max-width: calc(100vw - 2.8rem - 45px);
          }
        }
      }

      .input-control {
        display: inline-block;
        vertical-align: middle;
        margin-left: 1.2rem;

        @include mobile {
          margin-left: 1.4rem;
        }
      }
    }
  }

  [type='checkbox'],
  [type='radio'] {
    position: absolute;
    opacity: 0;
    padding: 0;
    min-width: 0;
    min-height: 0;

    + .input-control {
      border-radius: 10px;

      @include mobile {
        width: 2.1rem;
        height: 2.1rem;
      }

      --box-color: #{white};

      display: inline-block;
      position: relative;
      cursor: pointer;
      padding: 0;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 100%;
      background-color: var(--box-color);
      border: 1px solid var(--input-border-color);
      transition-property: background-color, border-color;
      transition-duration: 0.25s;
      transition-timing-function: ease-in;
      will-change: background-color, border-color;

      &::after {
        --checkmark-color: transparent;

        content: '';
        position: absolute;
        pointer-events: none;
        background: var(--checkmark-color);
        transition-duration: 0.25s;
        transition-timing-function: ease-in;
        transition-property: background-color;
        will-change: background-color;
      }

      &:hover {
        @include after-mobile {
          --box-color: var(--secondary-light-color);
          border-color: var(--primary-base-color);
        }

        &::after {
          @include after-mobile {
            --checkmark-color: var(--secondary-dark-color);
          }
        }
      }
    }

    &:disabled + .input-control {
      color: #b8b8b8;
      cursor: auto;
    }

    &:checked + .input-control {
      background-color: white;
      border-color: var(--input-border-color);

      &::after {
        --checkmark-color: var(--brand-green-color) !important;
      }
    }
  }

  [type='checkbox'] {
    + .input-control {
      &::after {
        left: 6px;
        top: 6px;
        width: 0.24rem;
        height: 0.24rem;
        box-shadow: 2px 0 0 var(--checkmark-color),
          4px 0 0 var(--checkmark-color), 4px -2px 0 var(--checkmark-color),
          4px -4px 0 var(--checkmark-color), 4px -6px 0 var(--checkmark-color),
          4px -8px 0 var(--checkmark-color);
        transform: rotate(45deg) scale(1.5);
        transition-property: background-color, box-shadow;
        will-change: background-color, box-shadow;

        @include mobile {
          left: 0.35rem;
          top: 0.84rem;
          width: 0.28rem;
          height: 0.28rem;
        }
      }
    }

    &:checked {
      &:hover {
        + .input-control {
          @include after-mobile {
            background-color: white;
          }
        }
      }
    }
  }

  [type='radio'] {
    + .input-control {
      border-radius: 50%;

      &::after {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        transition-property: background-color, box-shadow;
        will-change: background-color, box-shadow;

        @include mobile {
          width: 0.7rem;
          height: 0.7rem;
        }
      }
    }

    &:focus + .input-control {
      --box-color: var(--secondary-light-color);

      border-color: var(--primary-base-color);

      &::after {
        --checkmark-color: #{$white};
      }
    }
  }

  [type='file'] {
    border-style: dashed;
    cursor: pointer;
    height: 180px;
    opacity: 0;
    max-width: 100%;
    z-index: 1;

    + label {
      align-items: center;
      border: 2px dashed var(--input-border-color);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 180px;
      position: absolute;
      top: 0;
      width: 100%;

      p {
        font-size: 1.6rem;
        margin-bottom: 0;
      }

      span {
        color: var(--primary-base-color);
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      .Image {
        margin-bottom: 10px;
        text-align: center;
      }

      + p {
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
  }

  &-Labels {
    span {
      letter-spacing: 1.05px;
    }
  }
}
