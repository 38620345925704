/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';

.CategoryLeftMain {
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: -3px;
    left: 0;
    transition: opacity 200ms ease-in;
    will-change: transition;
    @include mobile {
        left: 50px;
    }
    &-NavBarMain {
        max-width: 350px;
        margin-top: 20px;
        background-color: white;
        border: 1px solid #d4d4d4;
        border-radius: 10px;
        padding: {
            left: 10px;
            right: 10px;
        }
        @include mobile {
            background-color: var(--brand-blue-color);
        }
        div:nth-last-child(1) {
            border: none;
        }
        &-Titles {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            border-bottom: 1px solid #d4d4d4;
            padding: 13px;
            font-weight: bold;
            &:hover {
                text-decoration: none;
            }

            &-Name {
                color: #6a6a6a;
                font-size: 15px;
                &:hover {
                    text-decoration: none;
                    color: #132d69;
                }
                @include mobile {
                    color: white;
                }
            }
            &-Svg {
                height: 15px;
                width: 15px;
                margin-top: 5px;
                @include mobile {
                    color: white;
                }
            }
        }
    }
}
