/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';

.Body {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 2rem;
    left: 0rem;
    right: 0;
    transition: opacity 200ms ease-in;

    &-MegaMenu {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 30% 1fr;
        grid-gap: 20px;
        position: fixed;
        overflow: scroll;

        @include tablet {
            width: 800px;
        }
        @media (min-width: 1200px) {
            width: 1200px;
            height: 450px;
        }
        background-color: #ffff;
        border-radius: 10px;
        padding: 2rem;
        margin: auto;
        &-Menu {
            &-HeaderName {
                font-size: 16px;
                font-weight: bold;
                color: #274c55;
                cursor: pointer;
            }
            &-LinkContainer {
                display: flex;
                flex-direction: column;
                margin-top: 1.5rem;
                &-Link {
                    font-size: 14px;
                    color: #395b64;
                    cursor: pointer;
                }
            }
        }
        &-MainBanner {
            grid-column: 4/5;
            grid-row: 1/3;
            &-MainBannerContainer {
                display: grid;
                grid-template-columns: 200px 200px;
                grid-template-rows: 150px 170px;
                grid-template-areas:
                    "CardTop CardTop"
                    "CardLeft CardRight";

                grid-gap: 20px;
                div:nth-child(1) {
                    grid-area: CardTop;
                    position: relative;
                    .Titles {
                        position: absolute;
                        left: 25rem;
                    }
                }
                div:nth-child(2) {
                    grid-area: CardLeft;
                    position: relative;
                    .Titles {
                        position: absolute;
                        left: 3rem;
                    }
                    margin-bottom: 20px;
                }
                div:nth-child(3) {
                    grid-area: CardRight;
                    position: relative;
                    .Titles {
                        position: absolute;
                        left: 3rem;
                    }
                    margin-bottom: 20px;
                }

                &-Main {
                    cursor: pointer;
                    //  border: 2px solid #f0f0f0;
                    border-radius: 13px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                    position: relative;
                    &-Img {
                        width: 100%;
                        height: auto;
                        border-radius: 13px;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    }
                    &-Titles {
                        text-align: center;

                        &-Name {
                            color: #f4aa0c;
                            font-size: 20px;
                        }
                        &-Description {
                            color: #f4aa0c;
                            font-weight: unset;
                            font-size: 1rem;
                        }
                        &-Button {
                            background-color: #095081;
                            color: #ebeff2;
                            font-size: 10px;
                            border-radius: 8px;
                            border: none;
                            padding: {
                                top: 8px;
                                bottom: 6px;
                                left: 15px;
                                right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
