/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --loader-scale: 1;
}

.Loader {
    background-color: rgba(255, 255, 255, .6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 75;
    margin: auto;
    opacity: 0;
    animation: appear 1ms 200ms forwards;

    &-Main {
        @extend %loader;
    }

    &-Scale {
        position: absolute;
        transform: translate(-50%, -50%) scale(var(--loader-scale));
        left: 50%;
        top: calc(50% - (3px * var(--loader-scale)));
    }
}


@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}

.circle-border {
    width: 40px;
    height: 40px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgb(144, 204, 141);
    background: linear-gradient(
        0deg,
        rgba(144, 204, 141, 0.1) 33%,
        rgba(144, 204, 141, 1) 100%
    );
    animation: spin 0.8s linear 0s infinite;
}

.circle-core {
    width: 100%;
    height: 100%;
    // top: 0;
    // left: 50%;
    // right: 0;
    // bottom: 0;
    // z-index: 75;
    margin: auto;
    background-color: #fff;
    border-radius: 50%;
}
