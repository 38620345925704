@font-face {
    font-family: "Poppins";
    src: url(/style/fonts/Poppins-Regular.woff2) format("woff2"),
        url(/style/fonts/Poppins-Regular.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

$font-poppins: "Poppins", sans-serif;

body {
    font-family: $font-poppins;
}
