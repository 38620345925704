/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
:root {
    --link-color: var(--primary-base-color);
    --link-hover: var(--primary-dark-color);
}

a {
    color: var(--link-color);

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
    }
}
