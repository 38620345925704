/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkbox-label-color: #0a0a0a;
    --my-account-input-label-color: rgba(10, 10, 10, 0.59);
    --my-account-overlay-width: 300px;
    --my-account-overlay-border-width: 1px;
    --home-base-color: #90cc8d;
    --home-button-color: #095081;
    --home-decription-color: #3a3a3a;
}

.MyAccountOverlay {
    --overlay-background: var(--overlay-content-background);

    justify-self: flex-end;
    // grid-column: 3;
    right: 0;
    padding-top: 1.2rem;
    border-radius: 20px;
    //   @include mobile {
    //     padding-top: 1.4rem;
    //   }
    @include tablet {
        max-height: 500px;
        overflow: scroll;
    }
    @include desktop {
        position: absolute;
        top: calc(var(--header-nav-height) - 10px);
        right: -40px;
        padding: 10px;
        width: var(--my-account-overlay-width);

        // border-radius: 20px;
        button:not(.Button_likeLink) {
            margin-bottom: 20px;
            border-radius: 10px;
            padding: 14px;
            font-size: 18px;
            background-color: var(--home-button-color);
            border: none;
            &:hover {
                background-color: var(--home-button-color);
            }
        }
        .Button_likeLink {
            color: var(--home-decription-color);
            box-shadow: none !important;
            font-size: smaller;
            color: var(--brand-blue-color);
            font-weight: 600;

            &:hover {
                color: var(--home-decription-color);
                text-decoration: underline !important;
                color: var(--brand-blue-color) !important;
                background-color: unset !important;
            }
        }
    }

    //   @include before-desktop {
    //     height: auto;
    //     padding-bottom: 0;
    //     top: 0;
    //     position: relative;
    //   }

    // &_isVisible {
    //     @include desktop {
    //         border: var(--my-account-overlay-border-width) solid var(--overlay-desktop-border-color);
    //     }
    // }

    &::before {
        @include desktop {
            content: "";
            position: absolute;
            top: -9px;
            right: 49px;
            height: 15px;
            width: 15px;

            transform: rotate(45deg);
            //   border-radius: 20px;
            background-color: var(--overlay-background);
            border: {
                top: var(--home-button-color) solid var(--home-button-color);
                left: var(--home-button-color) solid var(--home-button-color);
            }
        }
    }

    button {
        width: 100%;
        margin-top: 0.6rem;

        @include mobile {
            margin-top: 0.7rem;
        }
    }

    input {
        width: 100%;
        border-radius: 10px;
    }

    &-Heading {
        font-size: 1.8rem;
        font-weight: bold;
        padding: 20px 20px 0px 20px;
        color: var(--home-button-color);
        @include mobile {
            padding: 0 1.4rem 1.4rem;
        }

        @include before-desktop {
            display: none;
        }
    }

    &-Notification {
        background-color: var(--primary-dark-color);
        padding: 2.4rem;

        @include mobile {
            padding: 2.8rem;
        }
    }

    &-Navigation {
        li {
            margin: 0;
            padding: 0;

            &::before {
                content: none;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                display: block;
            }
        }

        a,
        button {
            width: 100%;
            text-align: left;
            padding: 1.2rem 2.4rem;
            font-size: 1.44rem;

            @include mobile {
                padding: 1.4rem 2.8rem;
                font-size: 1.68rem;
            }

            &:hover {
                background-color: var(--color-cart-light);
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }

            @include desktop {
                padding: 0.6rem;
            }
        }
        @include desktop {
            padding: 0.6rem;
        }
    }

    &-Action form,
    form {
        box-shadow: unset;
    }
    &-Additional {
        padding: {
            top: 0;
            left: 1.2rem;
            right: 1.2rem;
            bottom: 1.2rem;
        }
        // padding: 0px 20px;

        @include mobile {
            padding: {
                left: 1.4rem;
                right: 1.4rem;
                bottom: 1.4rem;
            }
        }
    }

    &-Action {
        display: grid;
        grid-template-rows: max-content 1fr;
        padding: 10px;

        &_state {
            &_createAccount {
                max-height: 500px;
                overflow: scroll;
                h4 {
                    padding-right: 1.2rem;
                    display: inline;

                    @include mobile {
                        padding-right: 1.4rem;
                    }
                }

                h3 {
                    margin-bottom: 1.2rem;

                    @include mobile {
                        margin-bottom: 1.4rem;
                    }
                }

                .Field {
                    @include mobile {
                        margin-top: 0.98rem;
                    }
                    margin-top: 20px;
                    margin-bottom: 20px !important;
                }

                @include before-desktop {
                    padding-top: 1.2rem;
                    padding: 10px;
                }

                @include mobile {
                    padding-top: 1.4rem;
                    padding: 10px;
                }
                &-AlreadyHaveAccount {
                    all: unset;
                    font-size: 30px;
                }
            }

            &_forgotPassword {
                // #forgot-password-label {
                //     font-size: smaller;
                //     color: unset;
                // }
                // #create-account-label {
                //     font-size: smaller;
                //     color: unset;
                // }
                .Button_likeLink {
                    box-shadow: unset;
                    color: var(--brand-blue-color);
                    margin-bottom: unset !important;
                    margin-left: 0.5rem !important;

                    &:hover {
                        text-decoration: underline !important;
                        color: var(--brand-blue-color) !important;
                        background-color: unset !important;
                    }
                }
                h4 {
                    width: initial;
                }

                button {
                    font-weight: bold;
                    font-size: 10px;
                }
            }

            &_signIn {
                h4 {
                    text-align: center;
                }
                .Field {
                    @include mobile {
                        margin-top: 0.98rem;
                    }
                    margin-top: 20px;
                    margin-bottom: 20px !important;
                }
            }

            &_loggedIn {
                .MyAccountOverlay-Heading {
                    @include desktop {
                        display: none;
                    }
                }
                @include desktop {
                    padding: 20px;

                    width: 180px;
                }
            }
        }

        h4 {
            font-size: 1.38rem;
            font-weight: bold;
            margin-bottom: 0.6rem;

            @include mobile {
                font-size: 1.61rem;
                margin-bottom: 0.7rem;
            }
        }

        h3 {
            margin-top: 0;
        }

        @include desktop {
            width: calc(
                var(--my-account-overlay-width) -
                    (var(--my-account-overlay-border-width) * 2)
            );
            border-radius: 20px;
        }
    }

    &-Additional {
        // padding-top: 40px;

        @include mobile {
            padding-top: 7.7rem;
        }

        section {
            // display: flex;
            padding-top: 20px;
            text-align: center;
            //   Create new
            button {
                margin-bottom: 20px;
                border: none;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                background-color: white !important;
                color: var(--home-base-color);
                &:hover {
                    color: var(--home-base-color) !important;
                }
            }

            &:first-of-type {
                padding-top: 0;
            }
        }

        &_state {
            &_createAccount {
                .MyAccountOverlay-SignInHere {
                    width: 100%;
                    font-weight: bold;
                    &:hover {
                        background-color: var(--brand-hover-color) !important;
                    }
                }
                .MyAccountOverlay-AlreadyHaveAccount {
                    font-size: small;
                    text-align: center;
                }
                // padding-top: 2.4rem;

                @include mobile {
                    padding-top: 2.8rem;
                }

                @include before-desktop {
                    display: none;
                }

                button {
                    width: initial;
                    height: initial;
                    margin: 0;
                    padding-left: 0.48rem;

                    @include mobile {
                        padding-left: 0.56rem;
                    }
                }
            }

            &_signIn {
                section {
                    flex-direction: column;
                }
            }

            &_confirmEmail {
                padding-top: 0;
                padding-bottom: 20px;
            }

            &_forgotPassword {
                padding: 0px 1.5rem;

                padding-bottom: 2rem;
                @include mobile {
                    padding-top: 3.92rem;
                    padding-left: 2.94rem;
                }

                @include before-desktop {
                    display: none;
                }
                .MyAccountOverlay-AlreadyHaveAnAccount {
                    font-size: small;
                    margin-bottom: 10px;
                }
                .MyAccountOverlay-DontHaveAnAccount {
                    font-size: small;
                    margin-bottom: 10px;
                }
                .MyAccountOverlay-SignInHere {
                    width: 100%;
                    &:hover {
                        background-color: var(--brand-hover-color) !important;
                    }
                }
                .MyAccountOverlay-CreateAnAccount {
                    width: 100%;
                    &:hover {
                        background-color: var(--brand-hover-color) !important;
                    }
                }
                section {
                    // margin-bottom: 5px;

                    button {
                        width: initial;
                        margin-top: 0;
                        padding-left: 5px;
                        font-weight: 600;
                    }

                    h4 {
                        margin-bottom: 0;
                        align-self: center;
                    }

                    &:last-of-type {
                        padding-top: 0.12rem;

                        @include mobile {
                            padding-top: 0.14rem;
                        }
                    }
                }
            }

            &_forgotPasswordSuccess {
                padding-top: 0;

                h4 {
                    font-size: 1.2rem;
                    line-height: 1.5;
                    padding-bottom: 1.68rem;
                    font-weight: normal;
                    letter-spacing: normal;

                    @include mobile {
                        font-size: 1.4rem;
                        padding-bottom: 1.96rem;
                    }
                }
            }
        }
    }

    &-CloseButton {
        &::before,
        &::after {
            background-color: var(--header-color);
            content: "";
            height: 20px;
            position: absolute;
            right: 27px;
            top: -13px;
            transform-origin: 50% 50%;
            width: 1px;
            z-index: 1;
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }

    &-ForgotPasswordButton {
        @include desktop {
            display: none;
        }
    }

    &-Buttons {
        margin-top: 0.6rem;
        border-radius: 10px;

        @include mobile {
            margin-top: 0.7rem;
        }
    }

    &-Button {
        &_likeLink {
            font-size: 0.5rem;
            margin-top: 1.44rem;
            padding: 0;

            margin: 0;
            @include mobile {
                font-size: 1.33rem;
                margin-top: 1.68rem;
            }
        }
    }
    input[type="checkbox"],
    input[type="radio"] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    input[type="checkbox"] + label,
    input[type="radio"] + label {
        position: relative;
        padding: 0.5rem 0 0 2rem;
        margin-bottom: 1rem;
        user-select: none;
        color: #3a3a3a;
    }
    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 23px;
        height: 23px;
        border: 1px solid #e4e5ea;
        border-radius: 50px;
        background: #fff;
        text-align: center;
        transition: background 200ms ease-out;
        margin-top: 5px;
    }
    input[type="checkbox"] + label:after,
    input[type="radio"] + label:after {
        content: "";
        position: absolute;
        transform: scale(0);
        transition: transform 200ms ease-out;
    }

    input[type="checkbox"] + label:after {
        background-color: transparent;
        content: "";
        display: block;
        position: absolute;
        left: 13px;
        top: 2px;
        width: 0.5rem;
        height: 1rem;
        opacity: 0;
        border-bottom: 2px solid #095081;
        border-right: 2px solid #095081;
        transform: rotate(45deg);
        transition: border-color 0.3s ease;
    }
    input[type="checkbox"]:checked + label:before {
        content: "";
    }
    input[type="checkbox"]:checked + label:after {
        content: "";
        opacity: 1;
    }

    &-Checkbox + label:after {
        background-color: transparent;
        content: "";
        display: block;
        position: absolute;
        left: 13px;
        top: 2px;
        width: 0.5rem;
        height: 1rem;
        opacity: 0;
        border-bottom: 2px solid #095081;
        border-right: 2px solid #095081;
        transform: rotate(45deg);
        transition: border-color 0.3s ease;
    }

    &-Checkbox {
        display: flex;
        flex-direction: row-reverse;
        right: 14px;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2.4rem;
        margin-top: 1.08rem;

        + .input-control {
            margin-left: 0.4rem;
            margin-right: 1rem;
        }

        @include mobile {
            margin-bottom: 2.8rem;
            margin-top: 1.26rem;
        }

        > label:first-of-type {
            align-items: center;
            color: var(--checkbox-label-color);
            font-size: 1.2rem;
            display: inline-flex;
            flex-direction: row-reverse;
            font-weight: 500;
            padding-bottom: 0;
            margin-left: 1.5rem;
        }

        > label:last-of-type {
            height: 1.32rem;
            @include mobile {
                height: 1.54rem;
            }

            &::after {
                left: 0.12rem;
                top: 0.48rem;
                width: 0.18rem;
                height: 0.18rem;

                @include mobile {
                    left: 0.14rem;
                    top: 0.56rem;
                    width: 0.21rem;
                    height: 0.21rem;
                }
            }
        }

        input {
            position: relative;
            width: 20px;
            height: 20px;
            left: 20px;
        }
    }
}
